import React from 'react';

const NavAbout = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 26.998H30" stroke="#344054" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M18 26.998V4.99805C18 4.73283 17.8946 4.47848 17.7071 4.29094C17.5196 4.1034 17.2652 3.99805 17 3.99805H5C4.73478 3.99805 4.48043 4.1034 4.29289 4.29094C4.10536 4.47848 4 4.73283 4 4.99805V26.998"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28 26.998V12.998C28 12.7328 27.8946 12.4785 27.7071 12.2909C27.5196 12.1034 27.2652 11.998 27 11.998H18"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M8 8.99805H12" stroke="#344054" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M10 16.998H14" stroke="#344054" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 21.998H12" stroke="#344054" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 21.998H24" stroke="#344054" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M22 16.998H24" stroke="#344054" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default NavAbout;
